  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  a {
    text-decoration: none !important;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

